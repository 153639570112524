import { createStore } from 'vuex'

import app from './app'
import accounts from './accounts'
import auth from './auth'
import banks from './banks'
import cards from './cards'
import cloudStorage from './cloud-storage'
import errors from './errors'
import logs from './logs'
import moneyBoxes from './money-boxes'
import news from './news'
import putToAccounts from './put-to-accounts'
import rates from './rates'
import receipts from './receipts'
import remoteConfig from './remoteConfig'
import settings from './settings'
import tags from './tags'
import tagsCollections from './tags-collections'
import tarif from './tarif'
import transactions from './transactions'
import userStats from './user-stats'

export default createStore({
  modules: {
    app,
    accounts,
    auth,
    banks,
    cards,
    cloudStorage,
    errors,
    logs,
    moneyBoxes,
    news,
    putToAccounts,
    rates,
    receipts,
    remoteConfig,
    settings,
    tags,
    tagsCollections,
    tarif,
    transactions,
    userStats
  }
})
