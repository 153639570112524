import { addFirestoreDoc } from '@/firebase/firestore'
import { getUid } from '@/firebase/auth'
import { tracePerformance } from '@/firebase/performance'
import useNotifications from '@/composables/useNotifications'
import localizeFilter from '@/filters/localize.filter'
import store from '../store'

export default {
  state: {
    error: null
  },
  mutations: {
    setError(state, error) {
      if (error && error.code && error.code === 'unavailable' && store.getters.online === false) { return }

      const errorCodes = {
        'auth/user-not-found': 'UserNotFound',
        'auth/wrong-password': 'WrongPassword',
        'auth/email-already-in-use': 'EmailAlreadyInUse',
        'auth/invalid-email': 'InvalidEmail',
        'auth/argument-error': 'ArgumentError',
        'auth/too-many-requests': 'TooManyRequestsTryAgainLater',
        'auth/popup-closed-by-user': 'Canceled',
        'auth/account-exists-with-different-credential': 'AddSocialFromProfile',
        'permission-denied': 'NoAccess',
        'unavailable': 'NoInternetConnection'
      }

      if (error.code) {
        if (error.code === 'resource-exhausted') {
          state.firebaseQuotaReached = true
        }

        if (errorCodes[error.code]) {
          state.error = localizeFilter(errorCodes[error.code])
        } else {
          state.error = localizeFilter('Error')
        }
      } else {
        state.error = error
      }
    },
    clearError(state) {
      state.error = null
    },
    clearInfo(state) {
      state.error = null
    }
  },
  actions: {
    async saveErrorInfo({ commit, getters }, errorInfo) {
      if (!errorInfo) { return }

      commit('setError', errorInfo.error)

      console.log('[', errorInfo.location, ']: ', errorInfo.error)

      if (errorInfo.toastId) {
        const { toastify } = useNotifications()
        toastify.remove(errorInfo.toastId)
      }

      const noSaveErrors = [
        'unavailable',
        'auth/wrong-password',
        'auth/user-not-found',
        'auth/email-already-in-use',
        'auth/invalid-email',
        'auth/invalid-continue-uri',
        'auth/popup-closed-by-user',
        'auth/popup-blocked',
        'auth/account-exists-with-different-credential'
      ]

      if (errorInfo.error.code && noSaveErrors.indexOf(errorInfo.error.code) >= 0) { return }

      if (!getters.online) { return }

      const t = tracePerformance('saveErrorInfo')
      t.start()

      try {
        if (errorInfo.params) {
          for (const param of Object.keys(errorInfo.params)) {
            if (errorInfo.params[param] === undefined) { errorInfo.params[param] = 'undefined' }
          }
        }

        await addFirestoreDoc('errors', {
          timestamp: new Date(),
          error: errorInfo.error + '',
          location: errorInfo.location,
          owner: getUid(),
          params: errorInfo.params,
          active: true
        })
      } catch (e) {
        console.log(`[saveErrorInfo]: ${e}`)
      } finally {
        t.stop()
      }
    }
  },
  getters: {
    error: s => s.error
  }
}
