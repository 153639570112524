import { onCollectionSnapshotHandler } from '@/firebase/firestore'
import { tracePerformance } from '@/firebase/performance'
import { getUid } from '@/firebase/auth'
import useNotifications from '@/composables/useNotifications'
import localizeFilter from '@/filters/localize.filter'
import { prepareDateFields } from '@/utils/prepareDateFields'

export default {
  state: {
    syncTimestamp: null,
    logsUnsubscribe: null,
    renewedToastId: null
  },
  mutations: {
    setSyncTimestamp(state, date) {
      state.syncTimestamp = date
    },
    setLogsUnsubscribe(state, value) {
      state.logsUnsubscribe = value
    },
    setRenewedToastId(state, toastId) {
      state.renewedToastId = toastId
    },
    clearInfo(state) {
      state.syncTimestamp = null
      state.logsUnsubscribe = null
      state.renewedToastId = null
    }
  },
  actions: {
    async subscribeToLogs({ commit, dispatch, getters }, timestamp) {
      const t = tracePerformance('subscribeToLogs')
      t.start()

      if (!timestamp) {
        timestamp = new Date()
      }

      if (!getters.syncTimestamp || +timestamp !== +getters.syncTimestamp) {
        await commit('setSyncTimestamp', timestamp)
      }

      let lastTimestamp = new Date(+getters.syncTimestamp)

      await dispatch('unsubscribeLogs')

      try {
        const unsubscribe = onCollectionSnapshotHandler({
          collectionName: 'logs',
          wheres: [['owner', '==', getUid()], ['type', '==', 'sync'], ['timestamp', '>', lastTimestamp]],
          order: [['timestamp', 'asc']],
          successHandler: async querySnapshot => {
            querySnapshot.docChanges().forEach(async change => {
              if (change.type === 'added') {
                const logData = prepareDateFields(change.doc.data())

                if (logData.timestamp && +logData.timestamp > +lastTimestamp) { lastTimestamp = logData.timestamp }

                await dispatch('newLogReceived', logData)
              }
            })

            if (!querySnapshot.empty) {
              await dispatch('subscribeToLogs', lastTimestamp)
            }
          },
          errorHandler: (error) => {
            commit('setError', error)
            dispatch('logoutAndGoToLogin', { from: 'logs-subscription-error-handler' })
            return false
          }
        })
        await commit('setLogsUnsubscribe', unsubscribe)
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'subscribeToLogs', params: { timestamp } })
        return false
      } finally {
        t.stop()
      }
    },
    async unsubscribeLogs({ dispatch, getters }) {
      const t = tracePerformance('unsubscribeLogs')
      t.start()

      try {
        const logsUnsubscribe = getters.logsUnsubscribe
        if (logsUnsubscribe) {
          await logsUnsubscribe()
        }
      } catch (e) {
        dispatch('saveErrorInfo', { error: e, location: 'unsubscribeLogs', params: {} })
        return false
      } finally {
        t.stop()
      }
    },
    async newLogReceived({ commit, dispatch, state, getters }, logData) {
      if (!logData || !logData.data || !logData.id || !logData.place || !logData.action) {
        commit('setError', localizeFilter('CantSyncData'))
        return false
      }

      const id = logData.id
      const data = logData.data.info

      let showDataRenewedToast = false

      if (logData.place === 'auth') {
        if (logData.action === 'deleted') {
          dispatch('userDeletedCleanUp', logData.id)
        } else if (logData.action === 'login') {
          if (getters.sessionId !== logData.id) {
            if (!getters.canUseLimit('multiSessions', false)) {
              await dispatch('logoutAndGoToLogin')
            }
          }
        } else if (logData.action === 'logout') {
          if (getters.sessionId !== logData.id) {
            await dispatch('logoutAndGoToLogin')
          }
        }
      } else if (logData.place === 'transactions') {
        const transaction = getters.transactions[id]

        if (logData.action === 'added' && !getters.lessonStep) {
          let canAddTransactionToStore = true
          if (getters.transactionsSettingsFiltered) {
            const transactionsSettings = getters.transactionsSettings

            if (transactionsSettings.dateStart && data.date && +data.date < +transactionsSettings.dateStart) { canAddTransactionToStore = false }
            if (transactionsSettings.dateEnd && data.date && +data.date > +transactionsSettings.dateEnd) { canAddTransactionToStore = false }
            if (transactionsSettings.sumStart && data.sum && (+(+data.sum / 100).toFixed(2)) < +transactionsSettings.sumStart) { canAddTransactionToStore = false }
            if (transactionsSettings.sumEnd && data.sum && (+(+data.sum / 100).toFixed(2)) > +transactionsSettings.sumEnd) { canAddTransactionToStore = false }
            if (transactionsSettings.accountId && data.accountId && +data.accountId !== +transactionsSettings.accountId) { canAddTransactionToStore = false }
            if (transactionsSettings.tags && data.tags && +data.tags.length !== +transactionsSettings.tags.length) { canAddTransactionToStore = false }
          }

          if (canAddTransactionToStore) {
            await dispatch('transactionAdded', { transactionId: id, data, increaseTotalNumber: 1 })
            await commit('resetTransactions')
            showDataRenewedToast = true
          }
        } else if (logData.action === 'edited') {
          const setInfo = {
            transactionId: id,
          }

          if (logData.data.changeTags) {
            setInfo.changeTags = logData.data.changeTags
          }

          if (logData.data.changeAccounts) {
            setInfo.changeAccounts = logData.data.changeAccounts
          }

          if (logData.data.addTags) {
            setInfo.addTags = logData.data.addTags
          }

          if (logData.data.deleteTags) {
            setInfo.deleteTags = logData.data.deleteTags
          }

          if (transaction && data) {
            setInfo.transactionData = data
          }

          if (getters.transactions[id]) {
            showDataRenewedToast = true
          }

          await dispatch('transactionEdited', [setInfo])
        } else if (logData.action === 'deleted') {
          const setInfo = {}

          if (transaction) {
            setInfo.transactionId = id
          }

          if (logData.data.changeTags) {
            setInfo.changeTags = logData.data.changeTags
          }

          if (logData.data.changeAccounts) {
            setInfo.changeAccounts = logData.data.changeAccounts
          }

          if (getters.transactions[id]) {
            showDataRenewedToast = true
          }

          if (Object.keys(setInfo).length) {
            await dispatch('transactionDeleted', setInfo)
          }
        }
      } else if (logData.place === 'accounts') {
        if (logData.action === 'added' && !getters.lessonStep) {
          if (data) {
            showDataRenewedToast = true
            await dispatch('accountAdded', { accountId: id, data })
          }
        } else if (logData.action === 'edited') {
          if (getters.accounts[id]) {
            showDataRenewedToast = true
            await dispatch('accountEdited', { accountId: id, data })
          }
        } else if (logData.action === 'deleted') {
          showDataRenewedToast = true
          await dispatch('accountDeleted', id)
        }
      } else if (logData.place === 'tags') {
        if (logData.action === 'added' && !getters.lessonStep) {
          if (data) {
            await dispatch('tagAdded', {
              tagId: id,
              data,
              increaseTotalNumber: 1
            })

            showDataRenewedToast = true
          }
        } else if (logData.action === 'edited') {
          if (data) {
            if (data.active) {
              if (!getters.tags[id]) {
                await dispatch('fetchTag', id)
              } else {
                await dispatch('tagEdited', { tagId: id, data, deleteFromAllCollections: false })
              }
            } else {
              await dispatch('tagEdited', { tagId: id, data, deleteFromAllCollections: true })
            }

            showDataRenewedToast = true
          }
        } else if (logData.action === 'deleted') {
          if (getters.tags[id]) {
            showDataRenewedToast = true
          }
          await dispatch('tagDeleted', id)
          await commit('clearTagsSumsForTagId', id)
        }
      } else if (logData.place === 'tagsSums') {
        if (logData.action === 'added') {
          if (data && data.tagId && getters.tags[data.tagId]) {
            await dispatch('setTagSum', {
              tagsSumsId: id,
              data
            })
          }
        } else if (logData.action === 'edited') {
          if (data && getters.tagsSums[id]) {
            await dispatch('updateTagSum', {
              tagsSumsId: id,
              month: data.month,
              difference: data.difference
            })
          }
        } else if (logData.action === 'deleted') {
          if (getters.tagsSums[id]) {
            await dispatch('clearOneTagsSums', id)
          }
        }
      } else if (logData.place === 'tagsCollections') {
        if (logData.action === 'added' && !getters.lessonStep) {
          showDataRenewedToast = true
          await dispatch('tagsCollectionAdded', { tagsCollectionId: id, data, increaseTotalNumber: 1 })
        } else if (logData.action === 'edited') {
          if (data && getters.tagsCollections[id]) {
            showDataRenewedToast = true
            await dispatch('tagsCollectionEdited', { tagsCollectionId: id, data })
          }
        } else if (logData.action === 'deleted') {
          if (getters.tagsCollections[id]) {
            showDataRenewedToast = true
            await dispatch('tagsCollectionDeleted', { tagsCollectionId: id, increaseTotalNumber: -1 })
          }
        }
      } else if (logData.place === 'userTarif') {
        showDataRenewedToast = true

        if (data) {
          await dispatch('tarifDataReceived', data)
        } else {
          await dispatch('tarifDataReceived', null)
        }
      } else if (logData.place === 'news') {
        if (logData.action === 'deleted') {
          await commit('deleteNewsFromStore', id)
          await commit('resetNews')
        }
      } else if (logData.place === 'userStats') {
        if (logData.action === 'newsRead' && id) {
          await dispatch('newsReadReceived', new Date(id))
        }
      } else if (logData.place === 'userSettings') {
        if (logData.action === 'edited') {
          if (data) {
            showDataRenewedToast = true
            await dispatch('setUserSettings', data)
          }
        }
      } else if (logData.place === 'userDefaultAccount' && !getters.lessonStep) {
        if (logData.action === 'added' || logData.action === 'edited') {
          if (data) {
            showDataRenewedToast = true
            await dispatch('defaultAccountFetched', data)
          }
        }
      } else if (logData.place === 'spreads') {
        if (logData.action === 'added' && !getters.lessonStep) {
          showDataRenewedToast = true

          if (getters.spreads) {
            await dispatch('spreadEdited', { spreadId: id, data, increaseTotalNumber: 1 })
          } else {
            await commit('increaseTotalNumberOf', { field: 'spreads', number: 1 })
          }
        } else if (logData.action === 'edited') {
          if (getters.spreads && getters.spreads[id]) {
            showDataRenewedToast = true
            await dispatch('spreadEdited', { spreadId: id, data, increaseTotalNumber: 0 })
          }
        } else if (logData.action === 'deleted') {
          showDataRenewedToast = true
          await dispatch('spreadDeleted', id)
        }
      } else if (logData.place === 'cards') {
        if (logData.action === 'added' && getters.getLimitNumber('maxCards') && !getters.lessonStep) {
          if (data) {
            await dispatch('cardAdded', { cardId: id, data, increaseTotalNumber: 1 })
            showDataRenewedToast = true
          }
        } else if (logData.action === 'edited' && getters.getLimitNumber('maxCards')) {
          if (getters.cards[id]) {
            showDataRenewedToast = true
            await dispatch('cardEdited', { cardId: id, data })
          }
        } else if (logData.action === 'deleted') {
          if (getters.cards[id]) {
            showDataRenewedToast = true
          }
          await dispatch('cardDeleted', id)
        }
      } else if (logData.place === 'banks') {
        if (logData.action === 'added' && getters.getLimitNumber('maxBanks') && !getters.lessonStep) {
          if (data) {
            await dispatch('bankAdded', { bankId: id, data, increaseTotalNumber: 1 })
            showDataRenewedToast = true
          }
        } else if (logData.action === 'edited' && getters.getLimitNumber('maxBanks')) {
          if (getters.banks[id]) {
            showDataRenewedToast = true
            await dispatch('bankEdited', { bankId: id, data })
          }
        } else if (logData.action === 'deleted') {
          showDataRenewedToast = true
          await dispatch('bankDeleted', id)
        }
      } else if (logData.place === 'moneyBoxes') {
        if (logData.action === 'added') {
          if (data) {
            showDataRenewedToast = true
            await dispatch('moneyBoxAdded', { moneyBoxId: id, data })
          }
        } else if (logData.action === 'edited') {
          if (getters.moneyBoxes[id]) {
            showDataRenewedToast = true
            await dispatch('moneyBoxEdited', { moneyBoxId: id, data })
          }
        } else if (logData.action === 'deleted') {
          showDataRenewedToast = true
          await dispatch('moneyBoxDeleted', id)
        }
      } else if (logData.place === 'userProfile') {
        if (data) {
          if (data.name !== undefined) {
            showDataRenewedToast = true
            await commit('setUserName', data.name)
          }

          if (data.userPic !== undefined) {
            showDataRenewedToast = true
            await commit('setUserPic', data.userPic)
          }
        }
      }

      if (showDataRenewedToast && !getters.lessonStep) {
        const { toastify } = useNotifications()

        if (state.renewedToastId) {
          toastify.remove(state.renewedToastId)
          await commit('setRenewedToastId', null)
        }

        const toastId = toastify.info(localizeFilter('DataUpdated'), { icon: 'refresh' })
        await commit('setRenewedToastId', toastId)
      }
    }
  },
  getters: {
    syncTimestamp: s => s.syncTimestamp,
    logsUnsubscribe: s => s.logsUnsubscribe
  }
}